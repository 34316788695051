import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from "../../components/layout/Layout";
import VideoWrapper from "../../components/VideoWrapper";
import Seo from "../../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout mdxType="Layout">
  <Seo title="Post Procedure Instructions - pikespeakoralsurgery" description="Please review these important post-operative instructions before your oral surgery procedure at Pikes Peak Oral Surgery" mdxType="Seo" />
    <section className="container" style={{
        margin: '128px auto'
      }}>
    <VideoWrapper video="EMHYsUMJoaE" centered mdxType="VideoWrapper" />
        <h3>{`Swelling`}</h3>
        <p>{`Swelling is the body’s normal response to surgery and the beginning of the healing process. It varies among individuals and according to different procedures. Swelling will increase over the first 48–72 hours and subside over the course of 1 week. Sometimes swelling may persist for greater than 1 week, depending on the individual or procedure. Apply cold packs (15 minutes on, 15 minutes off) for the first 48 hours while awake. Keep the head elevated for the first 72 hours using pillows under the back and head while sleeping to reduce swelling.`}</p>
        <h3>{`Bleeding`}</h3>
        <p>{`Minor bleeding or blood-tinged saliva may occur for the first 24–48 hours. Bite on gauze for 2–3 hours following the surgery. If heavy bleeding still occurs, either replace with new gauze or a moist tea bag and bite for an additional 1–2 hours. The key to stop bleeding is firm pressure on the location that is bleeding. Avoid drinking through a straw, sucking on candy, or smoking for at least 1 week following your surgery, as this can cause bleeding or result in a dry socket.`}</p>
        <h3>{`Pain`}</h3>
        <p>{`Medication has been prescribed according to your anticipated level of discomfort. Take pain medication before the numbness from the local anesthesia is gone. Repeat as needed according to the schedule printed on the prescription bottle. Eating or drinking prior to taking pain medication will help to avoid nausea. Do not drive, operate hazardous machinery, or drink alcohol while taking the pain medications. You may also take 2 ibuprofen (Advil®) every 6 hours beginning the day before surgery and continue taking them along with your prescription medications after surgery. Do not exceed the prescribed or recommended dosage, as this can lead to overdose, organ damage, or even death.`}</p>
        <h3>{`Rinsing`}</h3>
        <p>{`You may drink, but do not rinse your mouth for the first 24 hours following surgery. After 24 hours, gently rinse your mouth with warm salt water (1 teaspoon of salt in a glass of warm water) 3–4 time a day. Continue rinsing until you return for your post-op exam. You may gently brush your teeth the day after surgery, being careful in the area where surgery was performed.`}</p>
        <p>{`REMINDER: Starting 4 days after surgery, use the syringe to irrigate after meals and at bedtime. Do so until the sockets are healed. This will help to remove food debris.`}</p>
        <p>{`Peridex Mouth Rinse: If you have been prescribed Peridex mouth rinse, begin using this on the third day following your surgery.`}</p>
        <h3>{`Diet`}</h3>
        <p>{`After surgery, a soft liquid diet may be best for the first 48 hours. Cool foods, such as milkshakes and lukewarm soups may be the most comfortable. You may begin drinking once bleeding is well-controlled. Eating may resume once numbness from your tongue is gone to avoid biting your tongue.`}</p>
        <h3>{`Sutures`}</h3>
        <p>{`If sutures were placed, they will dissolve on their own, unless you were told that non-dissolving suture were used. Sutures may loosen or come out prior to your next appointment.`}</p>
        <p>{`Be assured that our interest in your well-being does not end with your surgery. If you have any questions or problems, please call us at the office number. Help will always be available. A post-op appointment will be made for approximately 1 week after surgery, but we would be happy to see you sooner should the need arise.`}</p>
        <p>{`Please call the office with any questions regarding your after-surgery care.`}</p>
      </section>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      